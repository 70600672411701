import React, { createContext, useState } from 'react';
import { bcApi } from '../helpers/bigcommerce';

const ReviewContext = createContext();

const initialState = {
  open: false
};

export const ReviewProvider = ({ children }) => {
  const [state, setState] = useState(initialState);

  const showReviewForm = (open = true) => {
    setState({ ...state, open: open });
  };

  const addReview = async (product_id, data) => {
    return new Promise(async (res, rej) => {
      const endpoint = `catalog/products/${product_id}/reviews`;
      const reqBody = data;
      try {
        const { status } = await bcApi(endpoint, 'POST', reqBody);

        if (status === 200) {
          res(true);
        }
      } catch (error) {
        setState({ ...state, cartLoading: false, cartError: error });
        rej(error);
      }
    });
  };

  const getReviews = async (product_id, page, reviewId = null) => {
    let endpoint = `catalog/products/${product_id}/reviews?limit=2&page=${page}&status=1&sort=id`;
    if (reviewId) {
      endpoint = `catalog/products/${product_id}/reviews?&id=${reviewId}`;
    }
    return new Promise(async (res, rej) => {
      try {
        const { response, status } = await bcApi(endpoint, 'GET');
        if (status === 200 && response.data) {
          res(response);
        } else {
          rej(undefined);
        }
      } catch (error) {
        setState({ ...state, cartLoading: false, cartError: error });
        rej(error);
      }
    });
  };

  return (
    <ReviewContext.Provider
      value={{
        state,
        setState,
        addReview,
        showReviewForm,
        getReviews
      }}
    >
      {children}
    </ReviewContext.Provider>
  );
};

export default ReviewContext;
